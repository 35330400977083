import React from "react"
import { gql } from '@apollo/client'

import * as f from '../../components/BPMTable/BPMInputs'
import { 
    DataMapper, 
    TreeNodeComponentCreator,
    BudgetPeriodComponentCreator,
    NullClickStrategy, 
    TableFieldDescription,
    DataTransformer,
    SimpleTableDisplayStrategy,
} from '../../components/BPMTable/TableMapperCreator'
import tableCreatorCreatorFactoryCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import TableCreatorCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'


const tableQuery = gql`
    query BudgetReport (
            $budget: String, 
            $currency: String, 
    ){
        budgetReport (
            budget: $budget,
            currency: $currency, 
        ){
            edges {
                node {
                    glAccountName
                    glAccountDescription
                    level
                    isLeaf
                    costPerPeriod {
                        period {
                            date
                        }
                        cost
                    }
                }
            }
            count
        }
    }
`

const editMutator = null
const newMutator = null
const deleteMutator = null
const unitQuery = null

const BudgetReportTableCreatorCreatorQueries = {tableQuery, editMutator, newMutator, deleteMutator, unitQuery}

class BudgetReportTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "BudgetReportTableCreator"
    }

    generateFiledDesceriptions = () => {
        const tableColums = []

        tableColums.push(
            new TableFieldDescription(
                'glAccountName',
                new DataMapper('glAccountName', 'level'),
                new f.FilterDescription(new f.NullCreator()),
                {
                    label: 'Gl Account', 
                    componentCreator: new TreeNodeComponentCreator(), 
                    transform: new DataTransformer(({glAccountName, level}) =>  ({name: glAccountName, level: level})),
                    excelTransform: new DataTransformer(({glAccountName, level})=>(glAccountName)),
                    hideSort: true
                }
            )
        )

        tableColums.push(
            new TableFieldDescription(
                'isLeaf',
                new DataMapper('isLeaf'),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Gl Account Is Leaf', hideSort: true, hidenField: true}
            )
        )

        tableColums.push(
            new TableFieldDescription(
                'glAccountDescription',
                new DataMapper('glAccountDescription'),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Gl Description', hideSort: true}
            )
        )

        if (this.parentProps && this.parentProps.budgetByPeriods) {
            this.parentProps.budgetByPeriods.map((item, index) =>
                tableColums.push(
                        new TableFieldDescription(
                        'period_' + item.period,
                        new DataMapper('costPerPeriod'),
                        new f.FilterDescription(new f.NullCreator()),
                        {
                            label: item.period,
                            hideSort: true,
                            componentCreator: new BudgetPeriodComponentCreator(this.parentProps.symbol, index, 0),
                            transform: new DataTransformer(({costPerPeriod}) => (costPerPeriod)),
                            rowFontWeight: true
                        }
                    )
                )
            )
        }

        return tableColums;

    }
    
    budgetReportStyleEvaluator(data) {
        return data.glAccountName?.level == 0 ? {backgroundColor: '#cdcdcd', border:'0.1em solid #090909'} : {}
    }

    budgetReportCellStyleEvaluator(data) {
        return (data.glAccountName?.level == 0 || data.isLeaf == "false") ? {fontWeight:'bold'} : {}
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, BudgetReportTableCreatorCreatorQueries, new NullClickStrategy(), new SimpleTableDisplayStrategy(true))
        tableCreator.setDefaultRowHeight(25);
        tableCreator.setCustomRowStyleEvaluator(this.budgetReportStyleEvaluator);
        tableCreator.setCustomCellStyleEvaluator(this.budgetReportCellStyleEvaluator);

        tableCreator.addDescriptions(this.generateFiledDesceriptions())

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(BudgetReportTableCreator)

export {
    BudgetReportTableCreator,
    BudgetReportTableCreatorCreatorQueries
};