import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import {
    DataMapper,
    DefaultClickStrategy,
    TableFieldDescription,
} from '../../../components/BPMTable/TableMapperCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'

const tableQuery = gql`
    query AllBudgetAssociations(
        $orderField: String, 
        $first: Int, 
        $last: Int, 
        $after: String, 
        $before: String,
        $glAccount: String,
        $account: String,
        $concept: String,
    ) {
        allBudgetAssociations(
            orderField: $orderField,
            first: $first, 
            last: $last, 
            after: $after, 
            before: $before,
            glAccount: $glAccount,
            account: $account,
            concept: $concept
        ) {
            edges {
                node {
                    id
                    glAccount {
                        id
                        name
                    }
                    account {
                        id
                        identifier
                        provider {
                            id
                            name
                        }
                    }
                    concept {
                        id
                        name
                        technology {
                            id
                            name
                        }
                    }
                }
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const editMutator = gql`
    mutation EditGlAccountBudgetAssociation(
        $id: String!,
        $glAccount: String!,
        $account: String!,
        $concept: String!
    ) {
        editGlAccountBudgetAssociation(
            id: $id,
            glAccount: $glAccount,
            account: $account,
            concept: $concept
        ) {
            budgetAssociation {
                id
            }
        }
    }
`

const newMutator = gql`
    mutation CreateGlAccountBudgetAssociation(
        $glAccount: String!,
        $account: String!,
        $concept: String!
    ) {
        createGlAccountBudgetAssociation(
            glAccount: $glAccount,
            account: $account,
            concept: $concept
        ) {
            budgetAssociation {
                id
            }
        }
    }
`

const deleteMutator = gql`
    mutation DeleteGlAccountBudgetAssociation($ids: [String!], $allRecords: Boolean, $filters: String) {
        deleteGlAccountBudgetAssociation(ids: $ids, allRecords: $allRecords, filters: $filters) {
            result
        }
    }
`

const unitQuery = `
    glAccountBudgetAssociation(id: $id) {
        id
        glAccount {
            id
            name
        }
        account {
            id
            identifier
        }
        concept {
            id
            name
        }
    }
`

const GlAccountBudgetAssociationTableCreatorQueries = { tableQuery, editMutator, newMutator, deleteMutator, unitQuery }

class GlAccountBudgetAssociationTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "GlAccountBudgetAssociationTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator = this.createTableCreator(this.constructor.getId(), tableName, GlAccountBudgetAssociationTableCreatorQueries, new DefaultClickStrategy())
        tableCreator.addDescriptions([
            new TableFieldDescription(
                'concept',
                new DataMapper('concept.name'),
                new f.FilterDescription(new f.TextFilter('concept')),
                { label: 'Concept Name' }
            ),
            new TableFieldDescription(
                'technology',
                new DataMapper('concept.technology.name'),
                new f.FilterDescription(new f.TextFilter('technology')),
                { label: 'Technology' }
            ),
            new TableFieldDescription(
                'provider',
                new DataMapper('account.provider.name'),
                new f.FilterDescription(new f.TextFilter('provider')),
                { label: 'Vendor' }
            ),
            new TableFieldDescription(
                'account',
                new DataMapper('account.identifier'),
                new f.FilterDescription(new f.TextFilter('account')),
                { label: 'Billin Account' }
            ),
            new TableFieldDescription(
                'glAccount',
                new DataMapper('glAccount.name'),
                new f.FilterDescription(new f.TextFilter('glAccount')),
                { label: 'Gl Account' }
            ),
        ])

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(GlAccountBudgetAssociationTableCreator)

export {
    GlAccountBudgetAssociationTableCreator,
    GlAccountBudgetAssociationTableCreatorQueries
};