import React, { useState } from 'react'

import { Card } from '@material-ui/core'

import CanvasJSReact from '../../utils/canvasjs.react'
import { quantityTransformer } from '../../utils/tools'

var CanvasJSChart = CanvasJSReact.CanvasJSChart

const BudgetReportCharts = (props) => {
    const { t } = props
    const [symbol] = useState(props.symbol)
    const [budgetExpense] = useState(props.generalStatistics?.budgetExpense ? props.generalStatistics.budgetExpense.toFixed(0) : 0)
    const [actualExpense] = useState(props.generalStatistics?.actualExpense ? props.generalStatistics.actualExpense.toFixed(0) : 0)
    const [budgetBalance] = useState(props.generalStatistics?.budgetBalance ? props.generalStatistics.budgetBalance.toFixed(0) : 0)
    const [budgetByPeriods] = useState(props.budgetByPeriods ? props.budgetByPeriods : [])

    const spentPercentage = ((actualExpense / (budgetExpense | 1)) * 100)
    const currentAvailable = (actualExpense > budgetExpense) ? 0 : (actualExpense == 0) ? budgetExpense : budgetBalance

    const budgetDougnutChart = {
        animationEnabled: true,
        colorSet:"bubbleColors",
        title: {
            text: spentPercentage.toFixed(0) + '%',
            fontSize: 35,
            fontWeight: 'bold',
            fontFamily: "arial",
            fontColor: "#154d92",
            verticalAlign: "center",
            horizontalAlign: "center",
            dockInsidePlotArea: true,
        },
        data: [{
            type: "doughnut",
            toolTipContent: `{t}: {c} {s} ({b})`,
            startAngle: 270,
            innerRadius: 60,
            explodeOnClick: false,
            showInLegend: false,
            dataPoints: [
                {
                    c: symbol,
                    s: quantityTransformer(actualExpense),
                    t: t('budget:Spent'),
                    b: spentPercentage.toFixed(2) + '%',
                    y: actualExpense,
                },
                {
                    c: symbol,
                    s: quantityTransformer(currentAvailable),
                    t: t('budget:Available'),
                    b: (Math.max(0, 100-spentPercentage)).toFixed(2) + '%',
                    y: currentAvailable,
                },
            ]
        }],
    }

    let budget_periods_data = []
    let budget_periods_assign = []
    let stackedData = []

    for (let i = 0; i < budgetByPeriods.length; i++) {
        const period = budgetByPeriods[i]

        budget_periods_data.push(
            {
                label: period.period,
                c: symbol,
                y: Number(period.spent.toFixed(0)),
                t: quantityTransformer(period.spent),
            }
        )

        budget_periods_assign.push(
            {
                x: i,
                c: symbol,
                y: Number(period.assigned.toFixed(0)),
                t: quantityTransformer(period.assigned),
            }
        )    
    }

    stackedData.push({
        toolTipContent: `${t('budget:Period')}: {label}<br/>${t('budget:Spend')}: {c} {t}`,
        type: "column",
        name: "Budgets",
        showInLegend: false,
        dataPoints: budget_periods_data,
    })

    stackedData.push({
        toolTipContent: `${t('budget:Assigned')}: {c} {t}`,
        type: "line",
        dataPoints: budget_periods_assign,
        markerType: "none",
        color: "#154d92",
    })

    const budgetColumnChart = {
        animationEnabled: true,
        colorSet: "bubbleColor",
        toolTip: {
            shared: true,
        },
        legend: {
            verticalAlign: "center",
            horizontalAlign: "right",
            reversed: true,
            cursor: "pointer",
        },
        axisY: {
            labelFormatter: function(e){
				return  symbol + quantityTransformer(e.value, 0);
			}
        },
        data: stackedData,
    }

    return (
        <Card id="budgetCharts" style={{ height: 220, marginTop: 10, marginBottom: 10 }}>
            <div style={{ padding: '20px', display: 'flex', justifyContent: "space-between"}}>
                <div key="dougnutChart" style={{ flex: 1 , maxWidth: '20%'}}>
                    <CanvasJSChart
                        options = {budgetDougnutChart}
                        containerProps = {{height: "200px"}}
                    />
                </div>
                <div id="columnChart" style={{ flex: 1, maxWidth: '80%' }}>
                    <CanvasJSChart
                        containerProps = {{height: "200px"}}
                        options={budgetColumnChart}
                    />
                </div>
            </div>
        </Card>
    )
}

export default BudgetReportCharts
